import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const AnsweredQuestionCount = props => {
	const { totalQuestions, showDisclaimer } = props;
	const { noOfAnsweredQuestions, noOfOfflineSavedAnswers } = useSelector(
		state => state.studentAuth
	);
	return (<div>
		<p style={{ marginBottom: 0, fontSize: 18 }}>
			Answered questions {noOfAnsweredQuestions} / {totalQuestions}
			&nbsp; <InfoCircleOutlined onClick={showDisclaimer} style={{ color: '#8B8000' }} />
		</p>
		<p style={{ marginBottom: 0, fontSize: 18 }}>Offline saved: {noOfOfflineSavedAnswers}</p>
	</div>
	);
};

export default AnsweredQuestionCount;
