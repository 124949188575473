import React, { useEffect } from 'react';
import { Affix, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import AnsweredQuestionCount from './AnsweredQuestionCount';
import {
	setAnsweredQuestionCount,
	setStatesToInitial
} from '../../../stateManager/reducers/studentAuthSlice';

const ExamFooter = props => {
	const { examData, showDisclaimer } = props;
	const dispatch = useDispatch();

	useEffect(() => {
		const saveQuestions = localStorage.getItem('localSavedAnswers');
		if (saveQuestions) {
			const localData = JSON.parse(saveQuestions);
			const questionInLocal = localData[examData?._id];
			if (questionInLocal) {
				for (const key in questionInLocal) {
					if (Object.hasOwnProperty.call(questionInLocal, key)) {
						//TODO need to check this fuction properly
						dispatch(setAnsweredQuestionCount(key));
					}
				}
			} else {
				dispatch(setStatesToInitial('noOfAnsweredQuestions'));
				dispatch(setStatesToInitial('noOfOfflineSavedAnswers'))
				dispatch(setStatesToInitial('offlineAnswer'))
			}
		}
	}, []);

	return (
		<div>
			<Affix offsetBottom={0} className='exam-footer-wrap'>
				<div
					className='exam-footer'
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<AnsweredQuestionCount totalQuestions={examData?.questions?.length} showDisclaimer={showDisclaimer} />
					<Button
						className='green-btn'
						type='primary'
						onClick={props.showModalConfirm}
					>
						Submit
					</Button>
				</div>
			</Affix>
		</div>
	);
};

export default ExamFooter;
