import React from 'react';
import { Typography } from 'antd';

import { ReactComponent as CourseBg } from '../../assets/images/course-bg.svg';

const { Title, Paragraph } = Typography;

const CourseInfo = props => {
	const { courseInfo, style, className } = props;

	return (
		<div
			className={
				!!className ? 'course-info-card' + className : 'course-info-card'
			}
			style={style}
		>
			<span style={{ color: '#EE473f' }}>Course</span>
			<Title style={{ marginBottom: 5 }} level={3}>
				{courseInfo?.title}
			</Title>
			<Paragraph>{courseInfo?.description}</Paragraph>
			<div className='course-bg'>
				<CourseBg />
			</div>
		</div>
	);
};

export default CourseInfo;
