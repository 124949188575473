import React, { useEffect, useState, useMemo } from 'react';
import {
	Typography,
	Row,
	Col,
	Layout,
	Button,
	Card,
	Popconfirm,
	Tooltip,
	Divider,
	Modal
} from 'antd';
import {
	GoldenFilled,
	PrinterFilled,
	CheckCircleFilled,
	CloseCircleFilled,
	ExclamationCircleFilled,
	MinusCircleFilled,
	ClockCircleFilled
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import {
	getExamResultRequest,
	examRetakeRequest,
	getExamById
} from '../../../stateManager/reducers/studentAuthSlice';

import { compareArray } from '../../../constants/constFunction'

import SecondaryHeaderComponent from '../../Header/student/secondaryHeader.component';
import MCQComponent from '../../Questions/students/MCQ.component';
import CheckBoxComponent from '../../Questions/students/CheckBox.component';
import ParagraphComponent from '../../Questions/students/Paragraph.component';
import ShortAnsComponent from '../../Questions/students/ShortAns.component';
import StudentHeader from '../../Header/student/studentHeader.component';
import Spinner from '../../Common/Spinner';

import './StudentResult.page.style.css';

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const StudentResult = props => {
	const { examId, groupId } = props.match.params;
	const allExams = useMemo(()=>JSON.parse(localStorage.getItem('allExams')), []);

	const [examDetails, setExamDetails] = useState(null);
	useEffect(() => {
		
		if (allExams && allExams.length > 0) {
			allExams.map((exam) => {
				if (exam.groupId === groupId && !exam?.examId?.isPracticeExam && exam?.examId?._id === examId) {
					console.log('exam', exam)
					setExamDetails(exam);
				}
			})
		}
	}, [allExams])


	const dispatch = useDispatch();
	const history = useHistory();
	const [showPop, setPop] = useState(false);
	// eslint-disable-next-line
	const [isLoading, setLoading] = useState(false);
	const examResult = useSelector(state => state.studentAuth.examResult);
	const currentExam = useSelector(state => state.studentAuth.currentExam);
	const status = useSelector(state => state.studentAuth.status);
	const visible = useSelector(state => state.studentAuth.visible);
	const subjectName = localStorage.getItem('subjectOfSelectedExam');
	const selectedExamdetails = JSON.parse(localStorage.getItem('selectedExamdetails'));

	console.log('selectedExamdetails', selectedExamdetails)

	useEffect(() => {
		window.addEventListener('contextmenu', event => event.preventDefault());

		return () => {
			window.addEventListener('contextmenu', event => event.preventDefault());
		};
	}, []);

	useEffect(() => {
		async function fetchResult() {
			await dispatch(getExamById({ examId, groupId }));
			const res = await dispatch(getExamResultRequest({ examId, groupId }));
			if (res.payload?.data?.code === 'notPublishedYet') {
				history.goBack();
			}
			if (!res || res.error) {
				history.goBack();
			}
			if (res.payload?.status === '200') {
				calculateStats();
			}
		}
		fetchResult();
		// eslint-disable-next-line
	}, [examId]);

	const handleRetake = async () => {
		try {
			const res = await dispatch(
				examRetakeRequest({
					examId,
					studentId: examResult.studentId, groupId, resultPage: true
				})
			);
			if (res?.payload?.status === '200') {
				localStorage.removeItem('localSavedAnswers');
				history.push(`/live-exam/${examId}/${groupId}`);
			}
			setPop(!showPop);
		} catch (error) {
			console.log(error);
		}
	};

	const calculateStats = type => {
		// setLoading(true);
		if (examResult && examResult?.answers && examResult?.answers) {
			let correctCount = 0;
			let notAnsweredCount = 0;
			let incorrectCount = 0;
			let negativeCount = 0;
			for (let i = 0; i < examResult?.answers?.length; i++) {
				const element = examResult?.answers[i];
				const actual = element?.questionId?.answer
					? [...element?.questionId?.answer]
					: [];
				const ans = !!element?.answer ? [...element?.answer] : [];
				negativeCount += element?.marks < 0 ? Math.abs(element?.marks) : 0;
				if (
					element?.questionId?.type === 'MCQ' ||
					element?.questionId?.type === 'checkbox'
				) {
					if (!element?.answer || element?.answer?.length <= 0) {
						notAnsweredCount += 1;
					} else if (
						// JSON.stringify(actual.sort()) === JSON.stringify(ans.sort())
						// compareArray(actual, ans)
						element?.marks && element?.marks > 0
					) {
						correctCount += 1;
					} else {
						incorrectCount += 1;
					}
				}
			}
			return type === 'correct'
				? correctCount
				: type === 'incorrect'
					? incorrectCount
					: type === 'notAnswered'
						? notAnsweredCount
						: negativeCount.toFixed(2);
		}
	};

	const timeTaken = () => {
		if (examResult && examResult?.startsAt && examResult?.submittedAt) {
			const submitMoment = moment(examResult?.submittedAt);
			const startMoment = moment(examResult?.startsAt);

			// Getting the difference: hours (h), minutes (m) and seconds (s)
			let h = submitMoment.diff(startMoment, 'hours');
			let m = submitMoment.diff(startMoment, 'minutes') - 60 * h;
			let s = submitMoment.diff(startMoment, 'seconds') - 60 * 60 * h - 60 * m;

			// Formating in hh:mm:ss (appends a left zero when num < 10)
			let hh = ('0' + h).slice(-2);
			let mm = ('0' + m).slice(-2);
			let ss = ('0' + s).slice(-2);

			return `${hh}:${mm}:${ss}`;
		}
		return 0;
	};

	if (status === 'loading' || isLoading) {
		return <Spinner />;
	}

	console.log('examDetails', examDetails)
	return (
		<Layout>
			<Modal
				title="Title"
				visible={visible}
				footer={[
					<Button key='back' type='primary' onClick={() => { history.goBack() }}>
						Back
					</Button>
				]}
			>
				<p>This exam has been removed from your group. Please go back to the 'Exam' page to view other exams.</p>
			</Modal>
			<Header className='no-print'>
				<StudentHeader />
			</Header>
			<Layout style={{ background: '#cacffc' }}>
				<SecondaryHeaderComponent />
			</Layout>
			<Layout className='student-result-page-bg'>
				<Content className='custom-container section-padding sec-mh student-result-page'>
					<div className='print-wrapper'>
						<div className='print-screen'>
							<Card className='result-statistics ' style={{ marginBottom: 30 }}>
								<Content>
									<Row>
										<Col xs={24}>
											<span className='result-section-title'>
												Exam Result -
												<Text
													style={{
														fontSize: 26,
														fontWeight: 800,
														color:
															(examResult.marksObtained /
																currentExam.totalMarks) *
																100 >=
																currentExam.passMark
																? 'green'
																: 'red'
													}}
												>
													{(examResult.marksObtained / currentExam.totalMarks) *
														100 >=
														currentExam.passMark
														? ' PASSED'
														: ' FAILED'}
												</Text>
											</span>
											<Title
												level={3}
												style={{ marginBottom: 0, textAlign: 'center' }}
											>
												{currentExam?.title || 'Not given'}
											</Title>
											<Title
												level={4}
												style={{
													marginTop: 5,
													marginBottom: 0,
													textAlign: 'center',
													color: '#616161'
												}}
											>
												{subjectName}
											</Title>
											<div style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: 10,
												gap: 20
											}}>
												<div>
													Duration - {`${examDetails?.duration ?? 0} min` || 'Not given'}
												</div>
												<div>
													Start -{' '}
													{moment(examDetails?.startsAt).format('MMMM Do YYYY, h:mm:ss a') || 'Not given'}
												</div>
												<div>
													End -{' '}
													{moment(examDetails?.endsAt).format('MMMM Do YYYY, h:mm:ss a') || 'Not given'}
												</div>
										
											</div>
											<div
												className='result-score'
												style={{ textAlign: 'center' }}
											>
												<Text style={{ fontSize: 16, marginRight: 5 }}>
													<strong>
														Total Marks Obtained:{' '}
														<span
															style={{
																color:
																	(examResult.marksObtained /
																		currentExam.totalMarks) *
																		100 >=
																		currentExam.passMark
																		? '#0fa959'
																		: '#f44336'
															}}
														>
															{examResult?.marksObtained?.toFixed(2)} /{' '}
															{examResult?.gpaFactor
																? currentExam.totalMarks +
																examResult?.gpaFactor?.hsc * 5 +
																examResult?.gpaFactor?.ssc * 5
																: currentExam.totalMarks}{' '}
														</span>
													</strong>
												</Text>
												{examResult?.gpaFactor && (
													<Text style={{ fontSize: 16, marginRight: 5 }}>
														<strong>
															GPA Marks Obtained:{' '}
															<span
																style={{
																	color:
																		(examResult.marksObtained /
																			currentExam.totalMarks) *
																			100 >=
																			currentExam.passMark
																			? '#0fa959'
																			: '#f44336'
																}}
															>
																{examResult?.marksObtained?.toFixed(2) -
																	examResult?.withoutGPA?.toFixed(2)}{' '}
																/{' '}
																{examResult?.gpaFactor?.hsc * 5 +
																	examResult?.gpaFactor?.ssc * 5}{' '}
															</span>
														</strong>
													</Text>
												)}
												{examResult?.gpaFactor && (
													<Text style={{ fontSize: 16, marginRight: 5 }}>
														<strong>
															Exam Marks Obtained:{' '}
															<span
																style={{
																	color:
																		(examResult.marksObtained /
																			currentExam.totalMarks) *
																			100 >=
																			currentExam.passMark
																			? '#0fa959'
																			: '#f44336'
																}}
															>
																{examResult?.withoutGPA?.toFixed(2)} /{' '}
																{currentExam.totalMarks}{' '}
															</span>
														</strong>
													</Text>
												)}
											</div>
										</Col>
									</Row>
									<Divider style={{ margin: '15px 0' }} />
									<Row className='result-statistics-wrap'>
										<Col
											xs={12}
											sm={12}
											md={8}
											lg={4}
											className='result-statistic-item'
										>
											<div
												style={{
													display: 'flex',
													flexWrap: 'nowrap',
													alignItems: 'center'
												}}
											>
												<CheckCircleFilled
													style={{
														color: '#4caf50',
														fontSize: 18,
														marginRight: 5
													}}
												/>
												<Text style={{ fontSize: 16, marginRight: 5 }}>
													Correct:{' '}
												</Text>
											</div>
											<Text
												className='result-statistic-value'
												style={{ fontWeight: 800 }}
											>
												{calculateStats('correct')}
											</Text>
										</Col>
										<Col
											xs={12}
											sm={12}
											md={8}
											lg={4}
											className='result-statistic-item'
										>
											<div
												style={{
													display: 'flex',
													flexWrap: 'nowrap',
													alignItems: 'center'
												}}
											>
												<CloseCircleFilled
													style={{
														color: '#f44336',
														fontSize: 18,
														marginRight: 5
													}}
												/>
												<Text style={{ fontSize: 16, marginRight: 5 }}>
													Incorrect:{' '}
												</Text>
											</div>
											<Text
												className='result-statistic-value'
												style={{ fontWeight: 800 }}
											>
												{calculateStats('incorrect')}
											</Text>
										</Col>
										<Col
											xs={12}
											sm={12}
											md={8}
											lg={4}
											className='result-statistic-item'
										>
											<div
												style={{
													display: 'flex',
													flexWrap: 'nowrap',
													alignItems: 'center'
												}}
											>
												<ExclamationCircleFilled
													style={{
														color: '#ffc107',
														fontSize: 18,
														marginRight: 5
													}}
												/>
												<Text style={{ fontSize: 16, marginRight: 5 }}>
													Skipped:{' '}
												</Text>
											</div>
											<Text
												className='result-statistic-value'
												style={{ fontWeight: 800 }}
											>
												{calculateStats('notAnswered')}
											</Text>
										</Col>
										<Col
											xs={12}
											sm={12}
											md={8}
											lg={4}
											className='result-statistic-item'
										>
											<div
												style={{
													display: 'flex',
													flexWrap: 'nowrap',
													alignItems: 'center'
												}}
											>
												<MinusCircleFilled
													style={{
														color: '#9575cd',
														fontSize: 18,
														marginRight: 5
													}}
												/>
												<Text style={{ fontSize: 16, marginRight: 5 }}>
													Negative Marks:{' '}
												</Text>
											</div>
											<Text
												className='result-statistic-value'
												style={{ fontWeight: 800 }}
											>
												{calculateStats('negative')}
											</Text>
										</Col>
										<Col
											xs={12}
											sm={12}
											md={8}
											lg={4}
											className='result-statistic-item'
										>
											<div
												style={{
													display: 'flex',
													flexWrap: 'nowrap',
													alignItems: 'center'
												}}
											>
												<GoldenFilled
													style={{
														color: '#ffffff',
														background: '#036987',
														fontSize: 14,
														marginRight: 5,
														borderRadius: '50%',
														padding: 2
													}}
												/>
												<Text style={{ fontSize: 16, marginRight: 5 }}>
													Merit Pos.:{' '}
												</Text>
											</div>
											<Text
												className='result-statistic-value'
												style={{ fontWeight: 800 }}
											>
												{examResult?.rank || 'Not given'}
											</Text>
										</Col>
										<Col
											xs={12}
											sm={12}
											md={8}
											lg={4}
											className='result-statistic-item'
										>
											<div
												style={{
													display: 'flex',
													flexWrap: 'nowrap',
													alignItems: 'center'
												}}
											>
												<ClockCircleFilled
													style={{
														color: '#ff9800',
														fontSize: 18,
														marginRight: 5
													}}
												/>
												<Text style={{ fontSize: 16, marginRight: 5 }}>
													Completion:{' '}
												</Text>
											</div>
											<Text
												className='result-statistic-value'
												style={{ fontWeight: 800 }}
											>
												{timeTaken()}
											</Text>
										</Col>
									</Row>
								</Content>
							</Card>
							<Row align='middle' className='exam-result-nav-wrap'>
								<Col xs={24} sm={24}>
									<div className='exam-result-nav'>
										{currentExam?.isPracticeExam && (
											<Popconfirm
												title='Retaking exam will remove the previous submission.
											Do you want to continue?'
												okText='Yes'
												onCancel={() => setPop(!showPop)}
												onConfirm={() => handleRetake()}
												okButtonProps={{ loading: status === 'loading' }}
												visible={showPop}
												placement='top'
											>
												<Button
													className='green-btn'
													size='large'
													type='primary'
													style={{ margin: '10px' }}
													onClick={() => setPop(!showPop)}
												>
													Retake Exam
												</Button>
											</Popconfirm>
										)}

										<Tooltip title='Print exam result'>
											<Button
												className='print-btn'
												size='large'
												type='link'
												icon={<PrinterFilled />}
												style={{ margin: '10px' }}
												onClick={() => window.print()}
											>
												Print
											</Button>
										</Tooltip>
									</div>
								</Col>
							</Row>
							{!!examResult &&
								examResult.answers &&
								examResult.answers.map((question, index) => {
									if (typeof question.questionId !== 'object' || question?.questionId?.answer === undefined) {
										// Show modal that tells reload required
										window.location.reload();
									} else {
										return (
											<Row
												className='question-row'
												key={index}
												style={{
													paddingBottom: 40,
													// background: '#fff',
													paddingTop: index === 0 ? 15 : 0
												}}
											>
												<Col xs={24} className='questions-wrapper'>
													{question.questionId?.type === 'MCQ' && (
														<MCQComponent
															question={question}
															questionIndex={index}
															isResult={true}
														/>
													)}
													{question.questionId?.type === 'checkbox' && (
														<CheckBoxComponent
															question={question}
															questionIndex={index}
															isResult={true}
														/>
													)}
													{question.questionId?.type === 'shortAns' && (
														<ShortAnsComponent
															question={question}
															questionIndex={index}
															isResult={true}
														/>
													)}
													{question.questionId?.type === 'paragraph' && (
														<ParagraphComponent
															question={question}
															questionIndex={index}
															isResult={true}
														/>
													)}
												</Col>
											</Row>
										);

									}

								})}
						</div>
					</div>
				</Content>
			</Layout>
		</Layout>
	);
};

export default StudentResult;
