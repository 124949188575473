import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../constants/constString';

const initialState = {
	questionsForExam: [],
	unsavedQuestionsForExam: [
		{
			title: '',
			type: 'MCQ',
			options: [],
			answer: []
		}
	],
	examListByCourse: [],
	examDetails: {},
	status: 'idle',
	error: null
};

export const getExamById = createAsyncThunk(
	'exam/getExamById',
	async ({ examId, courseId }) => {
		try {
			const response = await instance.get(
				`/exam/id/${examId}${courseId ? '?courseId=' + courseId : ''}`
			);
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

const slice = createSlice({
	name: 'exam',
	initialState,
	reducers: {},
	extraReducers: {
		[getExamById.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getExamById.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			state.examDetails = action.payload.data;
		},
		[getExamById.rejected]: (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		}
	}
});

export default slice.reducer;
