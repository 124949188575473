import React from 'react';
import { Row, Col, Card, Typography, Progress, Button, Image } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { bucket_url } from '../../../constants/constString';
import DummyImage from '../../../assets/images/dummy-image.png';
import './CourseCard.style.css';

const { Title, Paragraph } = Typography;

const CourseCard = props => {
	const { data, courseCompletionData , cardType} = props;
	const history = useHistory();
	let  CardSrc = '';
	if(cardType === 'qna'){
		CardSrc = `/qa-forum-list/${data._id}`;
	}else{
		CardSrc = `/subjects/${data._id}`;
	}
	

	const completionData = courseCompletionData
		? courseCompletionData?.filter(item => item?.courseId === data?._id)
		: [];

	const getPercentage = () => {

		console.log('completionData', completionData);
		if (completionData && data) {
			const { video, file } = completionData;
			let seenContentCount = 0;
			for (let i = 0; i < completionData.length; i++) {
				const { video, file } = completionData[i];
				seenContentCount += (video?.length || 0) + (file?.length || 0);
			}

			const allLetures = JSON.parse(localStorage.getItem('allLectures')) || [];
			const allChapters = JSON.parse(localStorage.getItem('allChapters')) || [];
			const allQuestionSolves = JSON.parse(localStorage.getItem('allQuestionSolves')) || [];
			const lectures = allLetures.filter(item => {
				if (item?.courseId === data?._id) {
					return item;
				}
			});
			const chapters = allChapters.filter(item => item?.courseId === data?._id);
			const solves = allQuestionSolves.filter(item => item?.courseId === data?._id);

			let totalContents = 0;
			for (let i = 0; i < lectures.length; i++) {
				// const { videoContents, fileContents } = lectures[i];
				totalContents += lectures[i]?.videoContents ? lectures[i]?.videoContents.length : 0;
				totalContents += lectures[i]?.fileContents ? lectures[i]?.fileContents?.length : 0;
			}
			for (let i = 0; i < chapters.length; i++) {
				// const { videoContents, fileContents } = chapters[i];
				totalContents += lectures[i]?.videoContents ? lectures[i]?.videoContents.length : 0;
				totalContents += lectures[i]?.fileContents ? lectures[i]?.fileContents?.length : 0;
			}

			for (let i = 0; i < solves.length; i++) {
				// const { videoContents, fileContents } = chapters[i];
				totalContents += lectures[i]?.videoContents ? lectures[i]?.videoContents.length : 0;
				totalContents += lectures[i]?.fileContents ? lectures[i]?.fileContents?.length : 0;
			}

			const percent = (seenContentCount / totalContents) * 100;
			return Math.round(percent);
		}
		return 0;
	};

	return (
		<Link to={CardSrc} style={{ width: '100%' }}>
			<Card className='course-card' hoverable>
				<Row gutter={16}>
					<Col xs={24}>
						{!!data && !!data?.image ? (
							<Image
								className='card-cover-img'
								alt='example'
								placeholder={true}
								src={data?.image ? bucket_url + data?.image : DummyImage}
								fallback={DummyImage}
								width={'100%'}
							/>
						) : (
							<Image
								className='card-cover-img'
								alt='example'
								src={DummyImage}
								width={'100%'}
							/>
						)}
					</Col>
					<Col xs={24} md={24} lg={24} className='course-info'>
						<div
							className='course-card-meta'
							// to={`/subjects/${data._id}`}
							style={{ height: '100%', padding: '10px 20px' }}
						>
							<Title level={3} className='course-title'>
								{data?.name || 'Title'}
							</Title>
							<Paragraph
								className='course-card-description'
							>
								{data?.description}
							</Paragraph>
							{/* {cardType !== 'qna' &&
							<Progress
								strokeColor={{ '0%': '#87d068', '100%': '#87d068' }}
								percent={getPercentage()}
								format={percent => `${percent}%`}
							/>
							} */}
						</div>
					</Col>
				</Row>
			</Card>
		</Link>
	);
};

export default CourseCard;
